/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 导出文件配置
 * @Author: Anita
 * @Date: 2022-04-10 10:45:14
 * @LastEditTime: 2022-04-18 14:26:24
 * @LastEditors: Anita
 * @since eaglewood-iotp-is-web 1.0
 */
export function exportFile(fileName, res) {
  const dateTime = new Date().getTime()
  const blob = new Blob([res])
  const elink = document.createElement('a')
  elink.download = `${fileName}-${dateTime}.xlsx`
  elink.style.display = 'none'
  elink.href = URL.createObjectURL(blob)
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  // document.removeChild(elink);
}
