/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 工具类
 * @Author: Anita
 * @Date: 2022-04-02 15:24:36
 * @LastEditTime: 2022-04-26 15:38:14
 * @LastEditors: Anita
 * @since eaglewood-iotp-is-web 1.0
 */

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
  if (!str || str === 'undefined' || str === 'null') {
    return ''
  }
  return str
}

// 生成当前时间，转化格式为 YYYY-MM-DD hh:mm:ss
export function newTime() {
  const moment = require('moment')
  const formatString = 'YYYY-MM-DDTHH:mm:ss.sssZ'
  return moment(new Date()).format(formatString)
}

/**
 * @methods changeTime
 * @description 转换时间格式
 * @param {Object} params time 表示某一条时间数据
 */
export function changeTime(time) {
  const moment = require('moment')
  // 显示的时间格式
  const formatString = 'YYYY-MM-DD HH:mm:ss'
  return moment(time).format(formatString)
}
