/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 状态管理信息配置文件
 * @Author: Anita
 * @Date: 2022-03-28 14:11:09
 * @LastEditTime: 2022-04-11 20:26:09
 * @LastEditors: Anita
 * @since eaglewood-iotp-is-web 1.0
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'zh_CN'
  },
  mutations: {
    changeLanguage(state) {
      if (state.language === 'zh_CN') {
        state.language = 'en_US'
      } else {
        state.language = 'zh_CN'
      }
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {

  }
})
