/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/**
 * @Description: 设备Iot模型接口
 * @Author: Pierre
 * @Date: 2023-06-21 09:39:22
 * @LastEditTime: 2023-06-21 09:47:51
 * @LastEditors: Pierre
 * @Since: nvr
 */

import requset from '@/utils/request'
const firstApi = '/v1.0/iotModel'

// 数据列表
export function getIotModelList(query) {
  return requset({
    url: `${firstApi}`,
    method: 'get',
    params: query
  })
}

// 通过Id查询
export function getIotModelById(id) {
  return requset({
    url: `${firstApi}/${id}`,
    method: 'get'
  })
}