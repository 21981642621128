/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 设备巡检页面请求配置
 * @Author: Pierre
 * @Date: 2022-05-22 17:50:32
 * @LastEditTime: 2023-06-19 10:34:58
 * @LastEditors: Pierre
 * @Since: eaglewood-iotp-is-web 1.0
 */
import requset from '@/utils/request'
import { praseStrEmpty } from '@/utils/config'

const firstApi = '/v1.0/DeviceInspectionService'
// 列表
export function getDeviceInspectionConfigList(query) {
  return requset({
    url: `${firstApi}/deviceInspectionConfigs`,
    method: 'get',
    params: query
  })
}

// 修改信息
export function updateDeviceInspectionConfig(data) {
  return requset({
    url: `${firstApi}/deviceInspectionConfig`,
    method: 'put',
    data: data
  })
}

// 新增信息
export function addDeviceInspectionConfig(data) {
  return requset({
    url: `${firstApi}/deviceInspectionConfig`,
    method: 'post',
    data: data
  })
}

// 根据id删除信息
export function deleteDeviceInspectionConfig(id) {
  return requset({
    url: `${firstApi}/deviceInspectionConfigs/` + praseStrEmpty(id),
    method: 'delete'
  })
}

// 导出信息
export function exportDeviceInspectionConfigsAsExcelFile(data) {
  return requset({
    url: `${firstApi}/deviceInspectionConfigs/exportExcelFile`,
    method: 'post',
    params: data,
    responseType: 'blob' // 请求为blob类型
  })
}
