/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 程序页面请求配置
 * @Author: Anita
 * @Date: 2022-05-12 15:24:32
 * @LastEditTime: 2022-05-18 13:41:49
 * @LastEditors: Anita
 * @Since: eaglewood-iotp-is-web 1.0
 */
import requset from '@/utils/request'
import { praseStrEmpty } from '@/utils/config'

// 程序列表
export function getProgramList(query) {
  return requset({
    url: '/v1.0/ProgramService/programs',
    method: 'get',
    params: query
  })
}

// 修改程序信息
export function updateProgram(data) {
  return requset({
    url: '/v1.0/ProgramService/program',
    method: 'put',
    data: data
  })
}

// 新增程序信息
export function addProgram(data) {
  return requset({
    url: '/v1.0/ProgramService/program',
    method: 'post',
    data: data
  })
}

// 根据id删除程序信息
export function deleteProgram(programId) {
  return requset({
    url: '/v1.0/ProgramService/programs/' + praseStrEmpty(programId),
    method: 'delete'
  })
}

// 导出服务器信息
export function exportProgramsAsExcelFile(data) {
  return requset({
    url: '/v1.0/ProgramService/programs/exportExcelFile',
    method: 'post',
    params: data,
    responseType: 'blob' // 请求为blob类型
  })
}
