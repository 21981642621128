/*
 * @Description: i18n配置文件
 * @Author: Anita
 * @Date: 2022-03-28 16:08:14
 * @LastEditTime: 2022-04-10 11:48:30
 * @LastEditors: Anita
 * @Since: eaglewood-iotp-is-web 1.0
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';


// 引入各个语言配置文件
import zhLocale from './config/zh';
import enLocale from './config/en';
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhlocale from 'element-ui/lib/locale/lang/zh-CN';

Vue.use(VueI18n);

const messages = {
    en_US: {
        ...elementEnLocale,
        ...enLocale
    },
    zh_CN: {
        ...elementZhlocale,
        ...zhLocale
    }
}
  
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
    locale: 'zh_CN', // 语言标识
    // 设置文本内容
    messages
})
// 暴露i18n
export default i18n;
