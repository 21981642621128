/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/**
 * @Description: 设备巡检日志页面接口
 * @Author: Pierre
 * @Date: 2023-06-08 17:37:55
 * @LastEditTime: 2023-06-08 17:42:08
 * @LastEditors: Pierre
 * @Since: nvr
 */

import requset from '@/utils/request'
import { praseStrEmpty } from '@/utils/config'
const firstApi = '/v1.0/DeviceInspectionService'

// 巡检列表
export function getDeviceInspectionLogList(query) {
  return requset({
    url: `${firstApi}/deviceInspectionLogs`,
    method: 'get',
    params: query
  })
}

// 修改巡检信息
export function updateDeviceInspectionLog(data) {
  return requset({
    url: `${firstApi}/deviceInspectionLog`,
    method: 'put',
    data: data
  })
}

// 新增巡检信息
export function addDeviceInspectionLog(data) {
  return requset({
    url: `${firstApi}/deviceInspectionLog`,
    method: 'post',
    data: data
  })
}

// 根据id删除巡检信息
export function deleteDeviceInspectionLog(Id) {
  return requset({
    url: `${firstApi}/deviceInspectionLogs/` + praseStrEmpty(Id),
    method: 'delete'
  })
}

// 导出巡检信息
export function exportDeviceInspectionLogsAsExcelFile(data) {
  return requset({
    url: `${firstApi}/deviceInspectionLogs/exportExcelFile`,
    method: 'post',
    params: data,
    responseType: 'blob' // 请求为blob类型
  })
}
