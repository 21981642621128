/**
 * @Description: 联系人接口
 * @Author: Pierre
 * @Date: 2023-07-14 10:34:40
 * @LastEditTime: 2023-07-24 10:55:07
 * @LastEditors: Pierre
 * @Since: nvr
 */
import requset from '@/utils/request'
import { praseStrEmpty } from '@/utils/config'
const firstApi = '/v1.0/ContactService'

// 数据列表
export function getContactList(query) {
  return requset({
    url: `${firstApi}/contacts`,
    method: 'get',
    params: query
  })
}

// 修改设备管理系统信息
export function updateContact(data) {
  return requset({
    url: `${firstApi}/contact`,
    data,
    method: 'put'
  })
}

// 新增设备管理系统信息
export function saveContact(data) {
  return requset({
    url: `${firstApi}/contact`,
    data,
    method: 'post'
  })
}

// 批量删除设备管理系统
export function deleteContactById(ids) {
  return requset({
    url: `${firstApi}/contact/` + praseStrEmpty(ids),
    method: 'delete'
  })
}

// 导出巡检信息
export function exportContactsAsExcelFile(data) {
  return requset({
    url: `${firstApi}/contact/exportExcelFile`,
    method: 'post',
    params: data,
    responseType: 'blob' // 请求为blob类型
  })
}

// 下载导入模板
export function downloadContactExcelFile() {
  return requset({
    url: `${firstApi}/contact/downloadExcelFile`,
    method: 'get'
  })
}

// 导入联系人信息
export function importContactsExcelFile(file){
  var form = new FormData();
  form.append("file", file);
  return requset({
      url: `${firstApi}/contact/importExcelFile`,
      data: form,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
}