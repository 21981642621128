/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 英文配置文件
 * @Author: Anita
 * @Date: 2022-03-28 16:08:28
 * @LastEditTime: 2023-07-24 17:40:46
 * @LastEditors: Pierre
 * @since eaglewood-iotp-is-web 1.0
 */
const en = {
  // 操作
  operate: {
    operate:'operate',
    search: 'search',
    register: 'register',
    edit: 'edit',
    delete: 'delete',
    look: 'look',
    reset: 'reset',
    inspectionLog: 'inspectionLog',
    serverInfo: 'serverInfo',
    programInfo: 'programInfo',
    dataBackupInfo: 'dataBackupInfo',
    export: 'export',
    cancel: 'cancel',
    define: 'define',
    close:'close',
    save:'save',
    import: 'import',
  },
  // 默认信息
  placeholder: {
    select: 'Please select',
    input: 'Please enter',
    inspectedTime: {
      startTime: 'startTime',
      endTime: 'endTiem'
    }
  },
  // 导出
  export: {
    serverInfo: 'serverInfo',
    serverInspectionLog: 'serverInspectionLog',
    programInfo: 'programInfo',
    programInspectionLogInfo: 'programInspectionLogInfo',
    dataBackupInfo: 'dataBackupInfo',
    dataBackupInspectionLogInfo: 'dataBackupInspectionLogInfo',
    deviceInspectionConfig:'deviceInspectionConfig',
    deviceInspectionLog:'deviceInspectionLog'
  },
    // 提示信息
  message: {
    keyValue: 'Key value pair information cannot be empty!!',
    add: {
      success: 'Add successfully!!',
      fail: 'Add failed!!'
    },
    edit: {
      success: 'Edit successfully!!',
      fail: 'Edit failed!!',
      tips: 'Please select a piece of information to edit, only one!!'
    },
    delete: {
      message: 'This operation will permanently delete this information. Do you want to continue?',
      success: 'Deleted successfully!!',
      fail: 'Delete failed!!',
      tips: 'Please select at least one message before deleting!!'
    }
  },
  title: 'Tips',
  server: {
    placeholder: {
      serverId: 'please enter the serverID',
      serverIp: 'Please enter the serverIP',
      machineType: 'Please select machineType'
    },
    // 标题
    label: {
      serverId: 'serverID',
      serverName: 'serverName',
      serverIp: 'serverIP',
      ipAddress: 'IpAddress',
      hostName: 'hostName',
      machineType: 'machineType',
      contactId: 'contactId',
      memory: 'memory',
      diskSpace: 'diskSpace',
      registeredUserId: 'registeredUser',
      registeredTime: 'registeredTime',
      updatedUserId: 'updatedUserId',
      updatedTime: 'updatedTime',
      remark: 'remark',
      inspectionConfig: 'inspectionConfig',
      inspectionPeriod: 'inspectionPeriod',
      operate: 'operate'
    },
    title: {
      edit: 'Edit ServerInfo',
      register: 'Register ServerInfo',
      serverInfo: 'ServerInfo'
    },
    rules: {
      message: {
        id: "server ID can't be empty",
        name: "server Name can't be empty",
        ipAddress: "Ip Address can't be empty",
        hostName: "host Name can't be empty",
        inspectionConfig: "inspectionConfig can't be empty",
        memory: "memory can't be empty",
        diskSpace: "diskspace can't be empty",
        contactId: "contact ID can't be empty"
      }
    },
    machineType: {
      physical: 'physical machine',
      virtual: 'virtual machine',
      docker: 'docker'
    }
  },
  serverInspectionLog: {
    placeholder: {
      status: 'please  select the status',
      ipAddress: 'Please enter the ip Address',
      inspectedTime: {
        startTime: 'startTime',
        endTime: 'endTime'
      }
    },
    label: {
      ipAddress: 'ipAddress',
      status: 'status',
      id: 'ID',
      serverId: 'serverID',
      name: 'name',
      cpuUsage: 'CpuUsage',
      networkTraffic: 'networkTraffic',
      diskSpaceUsage: 'diskSpaceUsage',
      usedDiskSpace: 'usedDiskSpace',
      memoryUsage: 'memoryUsage',
      usedMemory: 'usedMemory',
      alarmType: 'alarmType',
      inspectedTime: 'inspectedTime(min)',
      alarmMessage: 'alarmMessage'
    },
    title: {
      inspectionLogInfo: 'InspectionLogInfo',
      serverInfo: 'ServerInfo',
      programInfo: 'ProgramInfo',
      programInspectionLogInfo: 'ProgramInspectionLogInfo'
    },
    alarmType: {
      cpuUsage: 'CpuUsage too high',
      memory: 'memoryUsage too high',
      networkTraffic: 'Abnormal network traffic',
      diskIO: 'Abnormal disk Io'
    },
    status: {
      normal: 'normal',
      anomalous: 'anomalous'
    }
  },
  // 程序
  program: {
    label: {
      id: 'programID',
      name: 'programName',
      serverId: 'serverID',
      technologyName: 'technologyName',
      technologyVersion: 'technologyVersion',
      portNumber: 'portNumber',
      contactId: 'contactId',
      memory: 'memory',
      diskSpace: 'diskSpace',
      registeredUserId: 'registeredUser',
      registeredTime: 'registeredTime',
      updatedUserId: 'updatedUserId',
      updatedTime: 'updatedTime',
      remark: 'remark',
      inspectionConfig: 'inspectionConfig',
      inspectionPeriod: 'inspectionPeriod',
      operate: 'operate'
    },
    title: {
      programInfo: 'programInfo',
      register: 'register',
      edit: 'edit'
    },
    rules: {
      message: {
        id: 'Program ID cannot be empty!!',
        name: 'Program name cannot be empty!!',
        serverId: 'Server ID cannot be empty!!',
        portNumber: 'portNumber cannot be empty!!',
        memory: 'memory cannot be empty!!',
        diskSpace: 'diskSpace cannot be empty!!',
        contactId: 'contact ID cannot be empty!!'
      }
    },
    alarmType: {
      cpuUsage: 'CpuUsage too high',
      memory: 'memoryUsage too high',
      networkTraffic: 'Abnormal network traffic',
      diskIO: 'Abnormal disk Io'
    }
  },
  programInspectionLog: {
    label: {
      id: 'programInspectionLogID',
      name: 'serverName',
      programId: 'programId',
      portNumber: 'portNumber',
      inspectedTime: 'inspectedTime(min)',
      otherMetrics: 'otherMetrics',
      cpuUsage: 'CpuUsage',
      networkTraffic: 'networkTraffic',
      diskSpaceUsage: 'diskSpaceUsage',
      usedDiskSpace: 'usedDiskSpace',
      memoryUsage: 'memoryUsage',
      usedMemory: 'usedMemory',
      alarmType: 'alarmType',
      alarmMessage: 'alarmMessage'
    },
    alarmType: {
      cpuUsage: 'CpuUsage too high',
      memory: 'memoryUsage too high',
      networkTraffic: 'Abnormal network traffic',
      diskIO: 'Abnormal disk Io'
    },
    title: {
      programInspectionLogInfo: 'programInspectionLogInfo'
    }
  },
  // 数据备份
  dataBackup: {
    label: {
      id: 'Data Backup ID',
      serverId: 'Server ID',
      programId: 'Program ID',
      sourcePath: 'Source Path',
      destinationPath: 'Destination Path',
      frequencey: 'Frequencey(Daily)',
      registeredUserId: 'Registered User',
      registeredTime: 'Registered Time',
      updatedUserId: 'Updated User',
      updatedTime: 'Updated Time',
      remark: 'remark',
      inspectionConfig: 'inspectionConfig',
      inspectionPeriod: 'inspectionPeriod(min)',
      operate: 'operate',
      daily: 'Daily'
    },
    title: {
      dataBackupInfo: 'dataBackupInfo',
      register: 'register',
      edit: 'edit'
    },
    rules: {
      message: {
        id: 'Data Backup ID cannot be empty!!',
        serverId: 'Server ID cannot be empty!!',
        sourcePath: 'Source Path cannot be empty!!',
        destinationPath: 'Destination Path cannot be empty!!',
        frequencey: 'Frequencey cannot be empty!!',
        inspectionConfig: 'InspectionConfig cannot be empty!!'
      }
    },
    alarmType: {
      abnoraml: 'LAST DATA BACKUP ABNORAML',
    }
  },
  // 数据备份巡检
  dataBackupInspectionLog: {
    label: {
      id: 'ID',
      serverId: 'Server ID',
      programId: 'Program ID',
      tenantId: 'Tanant ID',
      dataBackupId: 'Data Backup ID',
      inspectedTime: 'Inspected Time(min)',
      status: 'status',
      alarmType: 'alarmType',
      alarmMessage: 'alarmMessage'
    },
    title: {
      inspectionLogInfo: 'inspectionLogInfo',
      dataBackupInfo: 'dataBackupInfo'
    },
    alarmType: {
      lastDataBackup: 'LAST DATA BACKUP ABNORAML'
    }
  },
  // 设备巡检配置
  deviceInspectionConfig:{
    label: {
      id: 'ID',
      tenantId: 'Tenant ID',
      adminUnitId: 'Admin Unit',
      adminSystemId: 'Admin System',
      iotModelId: 'IoT Model',
      content: 'Content',
      interval: 'Interval(min)',
      createdUserId: 'Created User',
      createdTime: 'Created Time',
      updatedUserId: 'Updated User',
      updatedTime: 'Updated Time',
      remark: 'Remark',
      operate: 'Operate'
    },
    placeholder:{
      id:'Please enter the ID',
      adminUnitId: 'Please enter the adminUnit',
      adminSystemId: 'Please enter the adminSystem',
      iotModelId: 'Please enter the iotModel',
    },
    title: {
      Info: 'Device Inspection Config Info',
      register: 'Add Device Inspection Config',
      edit: 'Edit Device Inspection Config'
    },
    rules: {
      message: {
        id: 'ID cannot be empty!!',
      }
    }
  },
   // 设备巡检日志
  deviceInspectionLog:{
    id:'ID',
    deviceId:'deviceId',
    deviceName:'deviceName',
    iotModelId:'iotModelId',
    adminSystemId:'adminSystemId',
    adminUnitId:'adminUnitId',
    inspectedTime:'inspectedTime',
    status:'status',
    alarmType:'alarmType',
    alarmMessage:'alarmMessage',
    registeredTime:'registeredTime',
    title:{
      deviceInfo:'Device Info',
      inspectedLogInfo:'Inspected Log Info'
    }
  },
  // 设备管理系统页面
  deviceAdminSystem:{
    label:{
      id:'ID',
      name:'Name',
      adminUnitId:'adminUnit',
      adminUnitName:'adminUnit',
      contactId:'contact',
      contactName:'contact',
      remark:'remark',
      connectUserId:'connectUser',
      connectUserName:'connectUser',
      status:'status',// - 离线，1 – 正常在线，2 – 异常故障
      connectProtocol:'connectProtocol',//REST、MQTT、TCP/IP、HTTP
      deviceConnectMode:'connectMode',//1 - 设备直连，2 - 经过设备管理系统连接设备
      dataTransmitPeriod:'dataTransmitPeriod',
      inspectionConfig:'inspectionConfig',
      inspectionPeriod:'inspectionPeriod',
      registeredTime:'registeredTime',
      registeredUserId:'registeredUser',
      updatedTime:'updatedTime',
      updatedUserId:'updatedUser',
    },
    title:{
      info:'deviceAdminSystem Info',
      edit:'deviceAdminSystem Edit',
      save:'deviceAdminSystem Add',
      delete:'Delete'
    }
  },
  // 联系人页面
  contact:{
    label:{
      emailAddress:'EmailAddress',
      id:'ID',
      mobileNumber:'MobileNumber',
      name:'Name',
      phoneNumber:'PhoneNumber',
      registeredTime:'RegisteredTime',
      registeredUserId:'RegisteredUser',
      remark:'Remark',
      tenantId:'Tenant',
      updatedTime:'UpdatedTime',
      updatedUserId:'UpdatedUser',
      wechatId:'Wechat',
      upload:'Upload',
    },
    title:{
      info:'Contact Info',
      edit:'Contact Edit',
      save:'Contact Create',
      delete:'Contact Delete',
      import:'Contact Import'
    },
    message:{
      importTip:'Use a template to import',
      clearFiles:'ClearFiles',
      SysReturn:'System return message',
      download:'Download Import Template'
    }
  },
  // 状态
  status: {
    normal: 'normal',
    anomalous: 'anomalous'
  },
  language: {
    zh_CN: '中文',
    en_US: 'English'
  },
  //通用配置
  common:{
    status:'status',
    normal: 'normal',
    anomalous: 'anomalous',
    inputPlaceholder:'Please enter',
    selectPlaceholder:'Please select',
  }
}
export default en
