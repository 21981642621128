/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 中文配置文件
 * @Author: Anita
 * @Date: 2022-03-28 16:08:32
 * @LastEditTime: 2023-07-24 17:39:17
 * @LastEditors: Pierre
 * @since eaglewood-iotp-is-web 1.0
 */
const zh = {
  // 操作
  operate: {
    operate:'操作',
    search: '搜索',
    register: '注册',
    reset: '重置',
    edit: '编辑',
    delete: '删除',
    look: '查看',
    inspectionLog: '巡检日志',
    serverInfo: '服务器信息',
    programInfo: '程序信息',
    dataBackupInfo: '数据备份信息',
    export: '导出',
    cancel: '取消',
    define: '确定',
    close:'关闭',
    save:'保存',
    import: '导入',
  },
  // 默认信息
  placeholder: {
    select: '请选择',
    input: '请输入',
    inspectedTime: {
      startTime: '开始时间',
      endTime: '结束时间'
    }
  },
  // 导出
  export: {
    serverInfo: '服务器信息',
    serverInspectionLog: '服务器巡检信息',
    programInfo: '程序信息',
    programInspectionLogInfo: '程序巡检信息',
    dataBackupInfo: '数据备份信息',
    dataBackupInspectionLogInfo: '数据备份巡检信息',
    deviceInspectionConfig:'设备巡检配置信息',
    deviceInspectionLog:'设备巡检日志信息'
  },
  // 提示信息
  message: {
    keyValue: '键值对信息不能为空',
    add: {
      success: '添加成功！！',
      fail: '添加失败！！'
    },
    edit: {
      success: '编辑成功！！',
      fail: '编辑失败！！',
      tips: '请选择一条信息进行编辑,仅限一条！！'
    },
    delete: {
      message: '将会永久删除该信息. 是否继续?',
      success: '删除成功！！',
      fail: '删除失败！！',
      tips: '请选择至少一条信息进行删除！！'
    }
  },
  title: '提示',
  // 服务器
  server: {
    placeholder: {
      serverId: '请输入服务器ID',
      serverIp: '请输入服务器IP',
      machineType: '机器类型'
    },
    // 标题
    label: {
      serverId: '服务器ID',
      serverName: '服务器名称',
      serverIp: '服务器IP',
      ipAddress: 'IP地址',
      hostName: '主机名',
      machineType: '机器类型',
      contactId: '联系人',
      memory: '内存大小',
      diskSpace: '存储大小',
      registeredUserId: '注册用户',
      registeredTime: '注册时间',
      updatedUserId: '更新用户',
      updatedTime: '更新时间',
      remark: '备注',
      inspectionConfig: '巡检配置内容',
      inspectionPeriod: '巡检间隔时间(分钟)',
      operate: '操作'
    },
    title: {
      edit: '编辑服务器信息',
      register: '注册服务器信息',
      serverInfo: '服务器信息'
    },
    rules: {
      message: {
        id: '服务器ID不能为空',
        name: '服务器名称不能为空',
        ipAddress: 'IP地址不能为空',
        hostName: '主机名不能为空',
        inspectionConfig: '巡检配置内容不能为空',
        memory: '内存大小不能为空',
        diskSpace: '存储大小不能为空',
        contactId: '联系人不能为空'
      }
    },
    machineType: {
      physical: '物理机',
      virtual: '虚拟机',
      docker: '容器'
    }
  },
  // 服务器巡检
  serverInspectionLog: {
    placeholder: {
      status: '请输入巡检状态',
      ipAddress: '请输入IP地址',
      inspectedTime: {
        startTime: '开始时间',
        endTime: '结束时间'
      }
    },
    label: {
      ipAddress: 'IP地址',
      status: '巡检状态',
      id: '巡检日志ID',
      serverId: '服务器ID',
      name: '服务器名称',
      inspectedTime: '巡检时间',
      cpuUsage: 'CPU使用率',
      networkTraffic: '瞬时网络流量',
      diskSpaceUsage: '存储使用率',
      usedDiskSpace: '使用的存储空间',
      memoryUsage: '内存使用率',
      usedMemory: '使用的内存空间',
      alarmType: '异常告警类型',
      alarmMessage: '异常告警信息'
    },
    title: {
      inspectionLogInfo: '巡检日志信息',
      serverInfo: '服务器信息'
    },
    alarmType: {
      cpuUsage: 'CPU使用率过高',
      memory: '内存使用率过高',
      networkTraffic: '瞬时网络异常',
      diskIO: '异常磁盘IO'
    },
    status: {
      normal: '正常',
      anomalous: '异常告警'
    }
  },
  // 程序
  program: {
    label: {
      id: '程序ID',
      name: '程序名称',
      serverId: '服务器ID',
      technologyName: '技术名称',
      technologyVersion: '技术版本',
      portNumber: '端口号',
      contactId: '联系人',
      memory: '内存大小',
      diskSpace: '存储大小',
      registeredUserId: '注册用户',
      registeredTime: '注册时间',
      updatedUserId: '更新用户',
      updatedTime: '更新时间',
      remark: '备注',
      inspectionConfig: '巡检配置内容',
      inspectionPeriod: '巡检间隔时间(分钟)',
      operate: '操作'
    },
    title: {
      programInfo: '程序信息详情',
      register: '注册',
      edit: '编辑'
    },
    rules: {
      message: {
        id: '程序ID不能为空',
        name: '程序名称不能为空',
        serverId: '服务器ID不能为空',
        portNumber: '端口号不能为空',
        memory: '内存大小不能为空',
        diskSpace: '存储大小不能为空',
        contactId: '联系人ID不能为空'
      }
    },
    alarmType: {
      cpuUsage: 'CPU使用率过高',
      memory: '内存使用率过高',
      networkTraffic: '瞬时网络异常',
      diskIO: '异常磁盘IO'
    }
  },
  // 程序巡检
  programInspectionLog: {
    label: {
      id: '程序日志记录ID',
      name: '服务器名称',
      programId: '程序ID',
      portNumber: '端口号',
      inspectedTime: '巡检时间',
      cpuUsage: 'CPU使用率',
      status: '巡检状态',
      networkTraffic: '瞬时网络流量',
      otherMetrics: '其他指标',
      diskIo: '存储I/O',
      diskSpaceUsage: '存储使用率',
      usedDiskSpace: '使用的存储空间',
      memoryUsage: '内存使用率',
      usedMemory: '使用的内存空间',
      alarmType: '异常告警类型',
      alarmMessage: '异常告警信息'
    },
    alarmType: {
      cpuUsage: 'CPU使用率过高',
      memory: '内存使用率过高',
      networkTraffic: '瞬时网络异常',
      diskIO: '异常磁盘IO'
    },
    title: {
      programInspectionLogInfo: '程序巡检信息详情'
    }
  },
  // 数据备份
  dataBackup: {
    label: {
      id: '数据备份ID',
      serverId: '服务器ID',
      programId: '程序ID',
      sourcePath: '源文件/文件夹路径',
      destinationPath: '目标文件/文件夹路径',
      frequencey: '备份频繁度(天)',
      registeredUserId: '注册用户',
      registeredTime: '注册时间',
      updatedUserId: '更新用户',
      updatedTime: '更新时间',
      remark: '备注',
      inspectionConfig: '巡检配置内容',
      inspectionPeriod: '巡检间隔时间(分钟)',
      operate: '操作',
      daily: '每天'
    },
    title: {
      dataBackupInfo: '数据备份信息详情',
      register: '注册',
      edit: '编辑'
    },
    rules: {
      message: {
        id: '数据备份ID不能为空',
        serverId: '服务器ID不能为空',
        sourcePath: '源数据文件/文件路径不能为空',
        destinationPath: '目标数据文件/文件路径不能为空',
        frequencey: '备份频繁度不能为空',
        inspectionConfig: '巡检配置内容不能为空'
      }
    },
    alarmType: {
      abnoraml: '最后数据备份异常',
    }
  },
  // 数据备份巡检
  dataBackupInspectionLog: {
    label: {
      id: '日志记录ID',
      serverId: '服务器ID',
      programId: '程序ID',
      tenantId: 'iot平台租户ID',
      dataBackupId: '数据备份ID',
      inspectedTime: '巡检时间',
      status: '巡检状态',
      alarmType: '异常告警类型',
      alarmMessage: '异常告警消息'
    },
    title: {
      inspectionLogInfo: '巡检日志信息',
      dataBackupInfo: '数据备份信息'
    },
    alarmType: {
      lastDataBackup: '最后数据备份异常'
    }
  },
  // 设备巡检配置
  deviceInspectionConfig:{
    label: {
      id: '配置ID',
      tenantId: '租户ID',
      adminUnitId: '设备管理单位',
      adminSystemId: '设备管理系统',
      iotModelId: '设备IoT模型',
      content: '内容',
      interval: '巡检间隔(分钟)',
      createdUserId: '创建用户',
      createdTime: '创建时间',
      updatedUserId: '更新用户',
      updatedTime: '更新时间',
      remark: '备注',
      operate: '操作'
    },
    title: {
      Info: '设备巡检配置信息',
      register: '注册设备巡检配置',
      edit: '编辑设备巡检配置'
    },
    rules: {
      message: {
        id: '配置ID不能为空',
      }
    }
  },
  // 设备巡检日志
  deviceInspectionLog:{
    id:'日志记录ID',
    deviceId:'设备ID',
    deviceName:'设备名称',
    iotModelId:'设备IoT模型',
    adminSystemId:'设备管理系统',
    adminUnitId:'设备管理单位',
    inspectedTime:'巡检时间',
    status:'巡检状态',
    alarmType:'异常告警类型',
    alarmMessage:'异常告警信息',
    registeredTime:'注册时间',
    title:{
      deviceInfo:'设备信息',
      inspectedLogInfo:'巡检日志信息'
    }
  },
  // 设备管理系统
  deviceAdminSystem:{
    label:{
      id:'ID',
      name:'名称',
      adminUnitId:'设备管理单位',
      adminUnitName:'设备管理单位',
      contactId:'联系人',
      contactName:'联系人',
      remark:'备注',
      connectUserId:'连接用户',
      connectUserName:'连接用户',
      status:'状态',// - 离线，1 – 正常在线，2 – 异常故障
      connectProtocol:'连接协议',//REST、MQTT、TCP/IP、HTTP
      deviceConnectMode:'连接模式',//1 - 设备直连，2 - 经过设备管理系统连接设备
      dataTransmitPeriod:'传输时间',
      inspectionConfig:'巡检配置',
      inspectionPeriod:'巡检周期',
      registeredTime:'创建时间',
      registeredUserId:'创建用户',
      updatedTime:'更新时间',
      updatedUserId:'更新用户',
    },
    title:{
      info:'设备管理系统信息',
      edit:'设备管理系统编辑',
      save:'设备管理系统注册',
      delete:'设备管理系统删除'
    }
  },
  // 联系人页面
  contact:{
    label:{
      emailAddress:'电子邮箱',
      id:'ID',
      mobileNumber:'手机号码',
      name:'姓名',
      phoneNumber:'电话号码',
      registeredTime:'创建时间',
      registeredUserId:'创建用户',
      remark:'备注',
      tenantId:'租户',
      updatedTime:'更新时间',
      updatedUserId:'更新用户',
      wechatId:'微信号',
      upload:'点击上传',
    },
    title:{
      info:'联系人信息',
      edit:'联系人编辑',
      save:'联系人注册',
      delete:'联系人删除',
      import:'联系人导入'
    },
    message:{
      importTip:'请使用模板导入',
      clearFiles:'清空上传列表',
      SysReturn:'系统返回信息',
      download:'点击下载导入模板'
    }
  },
  // 状态
  status: {
    normal: '正常',
    anomalous: '异常告警'
  },
  language: {
    zh_CN: '中文',
    en_US: 'English'
  },
  //通用配置
  common:{
    status:'状态',
    normal: '正常',
    anomalous: '异常告警',
    inputPlaceholder:'请输入关键字',
    selectPlaceholder:'-请选择-',
  }
}
export default zh
