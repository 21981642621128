/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 数据备份巡检页面请求配置
 * @Author: Anita
 * @Date: 2022-05-22 17:53:02
 * @LastEditTime: 2023-06-07 15:35:30
 * @LastEditors: Pierre
 * @Since: eaglewood-iotp-is-web 1.0
 */
import requset from '@/utils/request'
import { praseStrEmpty } from '@/utils/config'

// 程序巡检列表
export function getDataBackupInspectionLogList(query) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackupInspectionLogs',
    method: 'get',
    params: query
  })
}

// 修改程序巡检信息
export function updateDataBackupInspectionLogList(data) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackupInspectionLog',
    method: 'put',
    data: data
  })
}

// 新增程序巡检信息
export function addDataBackupInspectionLogList(data) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackupInspectionLog',
    method: 'post',
    data: data
  })
}

// 根据id删除程序巡检信息
export function deleteDataBackupInspectionLogList(Id) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackupInspectionLogs/' + praseStrEmpty(Id),
    method: 'delete'
  })
}

// 导出程序巡检信息
export function exportDataBackupInspectionLogsAsExcelFile(data) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackupInspectionLogs/exportExcelFile',
    method: 'post',
    params: data,
    responseType: 'blob' // 请求为blob类型
  })
}
