/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 数据备份页面请求配置
 * @Author: Anita
 * @Date: 2022-05-22 17:50:32
 * @LastEditTime: 2023-06-12 16:26:20
 * @LastEditors: Pierre
 * @Since: eaglewood-iotp-is-web 1.0
 */
import requset from '@/utils/request'
import { praseStrEmpty } from '@/utils/config'

// 程序列表
export function getDataBackupList(query) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackups',
    method: 'get',
    params: query
  })
}

// 修改程序信息
export function updateDataBackup(data) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackup',
    method: 'put',
    data: data
  })
}

// 新增程序信息
export function addDataBackup(data) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackup',
    method: 'post',
    data: data
  })
}

// 根据id删除程序信息
export function deletedataBackup(dataBackupId) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackups/' + praseStrEmpty(dataBackupId),
    method: 'delete'
  })
}

// 导出服务器信息
export function exportDataBackupsAsExcelFile(data) {
  return requset({
    url: '/v1.0/DataBackupService/dataBackups/exportExcelFile',
    method: 'post',
    params: data,
    responseType: 'blob' // 请求为blob类型
  })
}
