/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 路由信息配置文件
 * @Author: Anita
 * @Date: 2022-03-21 16:38:26
 * @LastEditTime: 2023-07-17 17:33:34
 * @LastEditors: Pierre
 * @since eaglewood-iotp-is-web 1.0
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

const ServerInspectionLog = () => import('../views/server-inspection-log.vue')
const Server = () => import('../views/server.vue')
const ProgramInspectionLog = () => import('../views/program-inspection-log.vue')
const Program = () => import('../views/program.vue')
const DataBackupInspectionLog = () => import('../views/data-backup-inspection-log.vue')
const DataBackup = () => import('../views/data-backup.vue')
const DeviceInspection = () => import('../views/device-inspection.vue')
const DeviceInspectionLog = () => import('../views/device-inspection-log.vue')
const Test = () => import('../views/test.vue')
const Test2 = () => import('../views/test2.vue')
const Test3 = () => import('../views/test3.vue')
const MapTest = () => import('../views/map-test.vue')
const Empty = () => import('../components/empty.vue')

Vue.use(VueRouter)

export const ConstantRoutes = [
  // 一级路由
  {
    path: '/',
    // redirect: '/server'
    // redirect: '/serverInspectionLog'
    // redirect: '/program'
    // redirect: '/programInspectionLog'
    // redirect: '/dataBackupInspectionLog'
    // redirect: '/dataBackup'
    // redirect: '/test'
    // redirect: '/test2'
    // redirect: '/contact'
    redirect: '/MapTest'
  },
  // 空页面
  {
    path: '/empty',
    component: Empty
  },
  // 服务器
  {
    path: '/server',
    component: Server
  },
  // 服务器巡检
  {
    path: '/serverInspectionLog',
    component: ServerInspectionLog
  },
  // 程序
  {
    path: '/dataBackup',
    component: DataBackup
  },
  {
    path: '/dataBackupInspectionLog',
    component: DataBackupInspectionLog
  },
  // 数据备份
  {
    path: '/program',
    component: Program
  },
  {
    path: '/programInspectionLog',
    component: ProgramInspectionLog
  },
  {
    path: '/deviceInspection',
    component: DeviceInspection
  },
  {
    path: '/deviceInspectionLog',
    component: DeviceInspectionLog
  },
  {
    path: '/deviceInspectionLog/:deviceId',
    props: route => ({param: route.query}),
    component: () => import('../views/device-inspection-log-id.vue')
  },
  {
    path: '/deviceAdminSystem',
    component: () => import('../views/device-admin-system')
  },
  {
    path: '/contact',
    component: () => import('../views/contact')
  },
  {
    path: '/test',
    component: Test
  },
  {
    path: '/test2',
    component: Test2
  },
  {
    path: '/test3',
    component: Test3
  },
  {
    path: '/mapTest',
    component: MapTest
  }
]

export default new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: ConstantRoutes
})
