/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 请求配置
 * @Author: Anita
 * @Date: 2022-04-02 15:37:51
 * @LastEditTime: 2023-07-14 10:40:02
 * @LastEditors: Pierre
 * @since eaglewood-iotp-is-web 1.0
 */
import * as apiServer from './ajax-server'
import * as ajaxServerInspectionLog from './ajax-server-inspection-log'
import * as ajaxProgram from './ajax-program'
import * as ajaxProgramInspectionLog from './ajax-program-inspection-log'
import * as ajaxDataBackup from './ajax-data-backup'
import * as ajaxDataBackupInspectionLog from './ajax-data-backup-inspection-log'
import * as ajaxDeviceInspection from '@/api/ajax-device-inspection'
import * as ajaxDeviceInspectionLog from '@/api/ajax-device-inspection-log'
import * as ajaxDeviceAdminSystem from '@/api/ajax-device-admin-system'
import * as ajaxIotModel from '@/api/ajax-iot-model'
import * as ajaxOrganizationUnit from '@/api/ajax-organization-unit'
import * as ajaxContact from '@/api/ajax-contact'
import * as ajaxCommon from '@/api/ajax-common'

export default {
  ...apiServer,
  ...ajaxServerInspectionLog,
  ...ajaxProgram,
  ...ajaxProgramInspectionLog,
  ...ajaxDataBackup,
  ...ajaxDataBackupInspectionLog,
  ...ajaxDeviceInspection,
  ...ajaxDeviceInspectionLog,
  ...ajaxDeviceAdminSystem,
  ...ajaxIotModel,
  ...ajaxOrganizationUnit,
  ...ajaxContact,
  ...ajaxCommon
}
