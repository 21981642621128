/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 服务器页面请求配置
 * @Author: Anita
 * @Date: 2022-04-02 10:45:34
 * @LastEditTime: 2022-05-16 11:16:18
 * @LastEditors: Anita
 * @since eaglewood-iotp-is-web 1.0
 */
import requset from '@/utils/request'
import { praseStrEmpty } from '@/utils/config'

// 服务器列表
export function getServerList(query) {
  return requset({
    url: '/v1.0/ServerService/servers',
    method: 'get',
    params: query
  })
}

// 修改服务器信息
export function updateServer(data) {
  return requset({
    url: '/v1.0/ServerService/server',
    method: 'put',
    data: data
  })
}

// 新增服务器信息
export function addServer(data) {
  return requset({
    url: '/v1.0/ServerService/server',
    method: 'post',
    data: data
  })
}

// 根据id删除服务器
export function deleteServer(serverId) {
  return requset({
    url: '/v1.0/ServerService/servers/' + praseStrEmpty(serverId),
    method: 'delete'
  })
}

// 导出服务器信息
export function exportServersAsExcelFile(data) {
  return requset({
    url: '/v1.0/ServerService/servers/exportExcelFile',
    method: 'post',
    params: data,
    responseType: 'blob' // 请求为blob类型
  })
}
