/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 服务器巡检请求配置
 * @Author: Anita
 * @Date: 2022-04-06 08:53:35
 * @LastEditTime: 2022-05-16 11:15:08
 * @LastEditors: Anita
 * @since eaglewood-iotp-is-web 1.0
 */
import requset from '@/utils/request'

// 服务器巡检列表
export function getServerInspectionLogList(query) {
  return requset({
    url: '/v1.0/ServerService/serverInspectionLogs',
    method: 'get',
    params: query
  })
}

// 导出服务器巡检
export function exportServerInspectionLogAsExcelFile(data) {
  return requset({
    url: '/v1.0/ServerService/serverInspectionLogs/exportExcelFile',
    method: 'post',
    responseType: 'blob', // 请求为blob类型，
    params: data
  })
}
