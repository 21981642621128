// import requset from '@/utils/request'
// import { praseStrEmpty } from '@/utils/config'
const firstApi = '/v1.0/ToolService'

// 通用下载方法
// export function download(fileName, isDelete = true) {
//   window.location.href =
//     `${firstApi}/common/download?fileName=` +
//     encodeURI(fileName) +
//     "&delete=" +
//     isDelete;
// }

export function commonDownload(fileName, isDelete = true) {
  // const params={
  //   fileName,
  //   isDelete
  // }
  // return requset({
  //   url: `${firstApi}/common/download`,
  //   method: 'get',
  //   params
  // })

   window.location.href =
    `${firstApi}/common/download?fileName=` +
    encodeURI(fileName) +
    "&delete=" +
    isDelete;
}