/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 程序入口文件
 * @Author: Anita
 * @Date: 2022-03-21 16:38:26
 * @LastEditTime: 2023-07-20 10:30:55
 * @LastEditors: Pierre
 * @since eaglewood-iotp-is-web 1.0
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import api from './api/index'
// 引入i18n国际化
import i18n from './i18n'
import ElementUI from 'element-ui'
import echarts from 'echarts'
// 引入自己发布的包
import EgwTable from 'yancode-test/dist/EgwTable.common'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/styles/element-variables.scss'
import '@/assets/styles/index.scss'
import '@/assets/styles/ruoyi.scss'


import { praseStrEmpty, newTime, changeTime } from '@/utils/config'
import { exportFile } from '@/utils/excel-export'


Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(EgwTable)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$echarts = echarts
Vue.prototype.praseStrEmpty = praseStrEmpty
Vue.prototype.newTime = newTime
Vue.prototype.changeTime = changeTime
Vue.prototype.exportFile = exportFile
Vue.prototype.$Map = window.TMap
Vue.prototype.$Location = new window.qq.maps.Geolocation('OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77', '腾讯地图测试')
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
