/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/**
 * @Description: 设备管理系统接口
 * @Author: Pierre
 * @Date: 2023-06-21 09:39:22
 * @LastEditTime: 2023-07-11 10:20:18
 * @LastEditors: Pierre
 * @Since: nvr
 */

import requset from '@/utils/request'
import { praseStrEmpty } from '@/utils/config'
const firstApi = '/v1.0/deviceAdminSystem'

// 数据列表
export function getDeviceAdminSystemList(query) {
  return requset({
    url: `${firstApi}`,
    method: 'get',
    params: query
  })
}

// 通过Id查询
export function getDeviceAdminSystemById(id) {
  return requset({
    url: `${firstApi}/` + praseStrEmpty(id),
    method: 'get'
  })
}

// 修改设备管理系统信息
export function updateDeviceAdminSystem(data) {
  return requset({
    url: `${firstApi}`,
    data,
    method: 'put'
  })
}

// 新增设备管理系统信息
export function saveDeviceAdminSystem(data) {
  return requset({
    url: `${firstApi}`,
    data,
    method: 'post'
  })
}

// 批量删除设备管理系统
export function deleteDeviceAdminSystemById(ids) {
  return requset({
    url: `${firstApi}/` + praseStrEmpty(ids),
    method: 'delete'
  })
}

// 导出巡检信息
export function exportDeviceAdminSystemsAsExcelFile(data) {
  return requset({
    url: `${firstApi}/exportExcelFile`,
    method: 'post',
    params: data,
    responseType: 'blob' // 请求为blob类型
  })
}