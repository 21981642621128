/**
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利。
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
*/
/*
 * @Description: 请求响应拦截处理
 * @Author: Anita
 * @Date: 2022-03-31 12:03:17
 * @LastEditTime: 2022-04-18 14:24:21
 * @LastEditors: Anita
 * @since eaglewood-iotp-is-web 1.0
 */
import axios from 'axios'
import store from '../store'

var timezone = (0 - new Date().getTimezoneOffset() / 60)
if (timezone > 0) {
  timezone = '+' + timezone
} else {
  timezone = '' + timezone
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['timeZoneId'] = timezone
axios.defaults.headers['language'] = store.state.language

const timeout = 60000

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: timeout
})

// 请求拦截
instance.interceptors.request.use(config => {
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截
instance.interceptors.response.use(res => {
  return res.data
}, err => {
  console.log(err)
})

// 暴露
export default instance
